<template>
	<v-container>
		<v-app-bar color="background" elevation="0" class="py-lg-2 pr-lg-3 pr-1">
			<v-btn icon rounded exact :to="{name: 'Account'}">
				<v-icon>mdi-arrow-left</v-icon>
			</v-btn>
		</v-app-bar>
		<v-row>
			<v-col cols="12" class="text-center pt-5">
				<h4 class="text-h4 text-center">stripe</h4>
				<p>choose your plan</p>
			</v-col>
		</v-row>
		<v-row class="justify-center">
			<v-col cols="12" md="6" lg="4" v-for="product in products">
				<v-card color="transparent">
					<v-card class="product">
						<v-card-title class="text-h6 accent">{{ product.name }}</v-card-title>
						<v-card-text class="mt-15">
							<h4 class="text-h5">{{ product.price }} / {{ product.frequency }}</h4>
							<p>{{ product.description }}</p>
						</v-card-text>
						<v-card-actions>
							<v-spacer></v-spacer>
							<v-btn @click="checkout(product)" rounded class="px-5" color="accent">select</v-btn>
						</v-card-actions>
					</v-card>
				</v-card>
			</v-col>
		</v-row>
	</v-container>
</template>

<script>
export default {
	name: "Checkout",
	mounted() {
		this.getProducts()
	},
	data: () => ({
		products: [
			{
				id: null,
				name: null,
				price: null,
				frequency: null,
				description: null,
			}
		]
	}),
	methods: {
		getProducts() {
			this.$api.stripe.products()
				.then(response => this.products = response.data)
				.catch(error => console.log(error))
		},
		checkout(product) {
			this.$api.stripe.checkout(product.id)
				.then(response => console.log(response))
				.catch(error => console.log(error))
		}
	}

}
</script>